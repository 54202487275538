// flow
import React, { useEffect, useRef, useState } from "react"
import {
  getLocalStorage,
  IS_MEMBER_PROMO_DISPLAYED,
} from "@lesmills/gatsby-theme-common"

import CancellationModalWrapper from "../CancellationModalWrapper"

type Props = {|
  btnSubmitLabel?: Array<LabelType>,
  handleCancelSubscription: () => void | Promise<void>,
  handleDisplayMemberPromoModal: () => void,
  handleDismiss: () => void | Promise<void>,
  isProcessing: Boolean,
  cancelSubscriptionData: Object,
  questionButtons: Object,
  user: Object,
  isSimplifiedMemberPromoJourney: Boolean,
  memberPromoCode: String,
|}

const CancellationConfirmModal = ({
  handleDisplayMemberPromoModal = () => {},
  handleCancelSubscription = () => {},
  handleDismiss = () => {},
  questionButtons,
  user,
  isSimplifiedMemberPromoJourney,
  memberPromoCode,
}: Props) => {
  const surveyIframeRef = useRef()
  const [isSurveyEnded, setIsSurveyEnded] = useState(false)

  useEffect(() => {
    window.addEventListener("message", handleMessage)
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [])

  useEffect(() => {
    if (isSurveyEnded) {
      const isMemberPromoDisplayed = getLocalStorage(IS_MEMBER_PROMO_DISPLAYED)
      if (isMemberPromoDisplayed === "true") {
        handleCancelSubscription()
      } else {
        handleDisplayMemberPromoModal()
      }
    }
  }, [isSurveyEnded])

  const handleOnLoad = () => {
    const iframeWindow = surveyIframeRef.current.contentWindow
    setTimeout(() => {
      const chargifyIdMatch = user?.chargifyUserId?.match(/:(\d+)/)
      const subscriptionIdMatch = user?.lmodSubscription?.subscription_id?.match(
        /:(\d+)/
      )
      if (chargifyIdMatch) {
        iframeWindow.postMessage(`chargifyId-${chargifyIdMatch[1]}`, "*")
      }
      if (subscriptionIdMatch) {
        iframeWindow.postMessage(
          `subscriptionId-${subscriptionIdMatch[1]}`,
          "*"
        )
      }
    }, 500)
  }

  const handleMessage = event => {
    if (event.source === surveyIframeRef.current.contentWindow) {
      if (event.data === "endOfSurvey") {
        setIsSurveyEnded(true)
      }
    }
  }
  return (
    <CancellationModalWrapper
      handleDismiss={() => handleDismiss()}
      isEnableBack={false}
      isConfirmSubscriptionModal={true}
      isEnableSkip={true}
      questionButtons={questionButtons}
      classNames={{
        content:
          "p-0 sm:w-max-content sm:h-max-content sm:m-0 sm:top-1/2 sm:left-1/2 mx-16 my-16 w-cancel-subscription-modal h-cancel-subscription-modal rounded-lg inset-x-50-16 inset-y-50-16",
      }}
      isMemberPromoAvailable={isSimplifiedMemberPromoJourney}
      memberPromoCode={memberPromoCode}
    >
      <iframe
        ref={surveyIframeRef}
        src={process.env.GATSBY_EXIT_SURVEY_URL}
        className={`rounded-lg md:w-800 sm:h-533 sm:w-600 w-full h-full pb-80 sm:pb-0`}
        onLoad={handleOnLoad}
      ></iframe>
    </CancellationModalWrapper>
  )
}

export default CancellationConfirmModal
