// flow
import React, { Node } from "react"
import {
  setLocalStorage,
  IS_MEMBER_PROMO_DISPLAYED,
} from "@lesmills/gatsby-theme-common"
import type { QuestionButtonsType } from "../../types/QuestionButtonsType"

import { Modal, Icon, Button } from "@lesmills/gatsby-theme-common"

type Props = {|
  handleDismiss: () => void | Promise<void>,
  handleBack?: () => void | Promise<void>,
  handleSkip?: () => void | Promise<void>,
  children?: Node,
  isEnableBack?: Boolean,
  isEnableSkip?: Boolean,
  isConfirmSubscriptionModal?: Boolean,
  questionButtons: QuestionButtonsType,
  testId?: string,
  classNames?: Object,
  isMemberPromoAvailable?: Boolean,
  memberPromoCode?: string,
|}

const CancellationModalWrapper = ({
  handleDismiss,
  handleBack = () => {},
  handleSkip = () => {},
  children,
  isEnableBack = false,
  isEnableSkip = false,
  isConfirmSubscriptionModal = false,
  questionButtons = {},
  testId = "",
  classNames = { content: "" },
  isMemberPromoAvailable,
  memberPromoCode,
}: Props) => {
  return (
    <Modal
      handleDismiss={handleDismiss}
      classNames={{
        wrapper: "bg-white md:bg-modal-base",
        content: classNames.content,
      }}
      isCustomisedContentPosition={true}
      testId={testId}
      nonePadding={true}
    >
      {isEnableBack && (
        <Button
          className={`text-base md:text-2lg md:leading-7none leading-snug font-base-light block text-gray-800 underline`}
          handleOnClick={handleBack}
          testId="back-btn"
        >
          <Icon icon="black-back" type="medium" />
          <span>{questionButtons.back}</span>
        </Button>
      )}
      {children}
      {isEnableSkip ? (
        isConfirmSubscriptionModal ? (
          <div
            className={`border-t border-gray-200 py-24 sm:relative absolute w-full bottom-0 bg-white rounded-b-lg sm:rounded-b-0`}
          >
            <Button
              className={`text-base md:text-2lg font-base-light text-gray-500 leading-normal flex justify-center underline mx-auto text-gray-800`}
              handleOnClick={() => handleDismiss()}
              testId="keep-subscription"
            >
              {questionButtons.keep}
            </Button>
            {isMemberPromoAvailable && (
              <Button
                className={`text-base md:text-2lg font-base-light text-gray-500 leading-normal flex justify-center items-center underline mx-auto text-gray-800 mt-10`}
                handleOnClick={() => {
                  setLocalStorage(IS_MEMBER_PROMO_DISPLAYED, "true")
                  window.location.href = `${process.env.GATSBY_MEMBER_PROMO_REDEMPTION_URL}?isFrom=cancellation&promoCode=${memberPromoCode}`
                }}
                testId="get-member-promo"
              >
                <Icon icon="offer" type="medium" />
                <span className="ml-8">{questionButtons.offer}</span>
              </Button>
            )}
          </div>
        ) : (
          <Button
            className={`text-base md:text-2lg font-base-light text-gray-500 leading-normal flex justify-center mt-35 mb-140 md:mb-20 underline mx-auto w-full`}
            handleOnClick={handleSkip}
            testId="skip-btn"
          >
            {questionButtons.skip}
          </Button>
        )
      ) : (
        ""
      )}
    </Modal>
  )
}

export default CancellationModalWrapper
